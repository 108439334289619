import { FC } from 'react';

interface Props {
  name: string
  icon?: string
}

const NameWithLogo: FC<Props> = ({ name, icon }) => {
  return (
    <>
      {icon ? <img height={16} width={16} src={icon} alt="Language" /> : null}
      <span style={{ marginLeft: 5 }}>{name}</span>
    </>
  );
};

export default NameWithLogo;
