
export type Example = {
  name: string
  category: string[]
  links: {
    name: string
    link: string
    icon?: string
  }[]
  callback?: {
    title: string
    link: (context: IState) => string
  }
  isDemo: boolean,
  icon?: string
  output: (context: IState) => string,
  users: { id: string, label: string, selected: boolean }[]
}

export type IState = {
  availableExamples: Example[]
  example: Example,
  selectExample: (index: number) => void,
  selectId: (id: string) => void,
  toggleCodePanel: () => void,
  id: string,
  showCodePanel: boolean
}

// Types
export const SELECT_EXAMPLE = 'SELECT_EXAMPLE';
export const SELECT_ID = 'SELECT_ID'
export const TOGGLE_CODE_PANEL = 'TOGGLE_CODE_PANEL'

// Context Dispatch function shapes
export interface selectExample {
  type: typeof SELECT_EXAMPLE
  payload: { index: number };
}
export interface selectId {
  type: typeof SELECT_ID
  payload: { id: string };
}
export interface toggleCodePanel {
  type: typeof TOGGLE_CODE_PANEL
  payload: {};
}
// Types for Reducers Action
export type Action = selectExample | selectId | toggleCodePanel
