import { FC, useEffect, useState } from 'react';
import Split from 'split.js';
import { useAppContext } from '../context';

// Styled Components
import GlobalStyles from '../styles/GlobalStyles';
import GlobalContainer from '../styles/GlobalContainer';
import MiddleContainer from '../styles/MiddleContainer';

// Components
import Sidebar from './sidebar';
import Editor from './editor';
import Output from './output';

const Main: FC = () => {
  const { showCodePanel } = useAppContext();

  const [split, setSplit] = useState<Split.Instance>()

  useEffect(() => {
    setSplit(Split(['#sidebar', '#code', '#output'], {
      gutterSize: 8,
      sizes: [20, 0, 90],
    }))
  }, [])

  useEffect(() => {
    split?.setSizes(showCodePanel ? [8, 42, 50] : [20, 0, 90])
  }, [showCodePanel, split]);

  return (
    <>
      <GlobalStyles />
      <GlobalContainer className="split">
        <Sidebar id="sidebar" />
        <MiddleContainer id="code" style={{ display: showCodePanel ? 'flex' : 'none' }} className="split">
          <Editor id="editor" />
        </MiddleContainer>
        <Output id="output" />
      </GlobalContainer>
    </>
  );
};

export default Main;
