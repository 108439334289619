import { FC } from 'react';
import { ToastContainer } from 'react-toastify';

import { useAppContext } from '../../context';

import SidebarSection from './Sidebar';
import { Files, FileItem, TopBar } from './Files';
import NameWithLogo from '../../utils/NameWithLogo';
import UserPicker from './UserPicker';

interface Props {
  id: string;
}

const Sidebar: FC<Props> = ({ id }) => {
  const {
    availableExamples,
    selectExample,
    example
  } = useAppContext();

  return (
    <SidebarSection id={id}>
      <ToastContainer
        position="bottom-left"
        closeOnClick={false}
        autoClose={false}
        draggable={false}
      />
      <Files key="demo">
        <TopBar>
          Reelevant Demo
        </TopBar>
        <UserPicker />
        {availableExamples.map((_example, i) => {
          if (_example.isDemo === false) return <div key={i}></div>
          return (
            <FileItem
              active={example.name === _example.name}
              key={_example.name}
              onClick={() => selectExample(i)}
            >
              <div>
                <NameWithLogo name={_example.name} icon={_example.icon} />
              </div>
            </FileItem>
          )
        })}
      </Files>
      <Files key="customers" style={{}}>
        <TopBar>
          Customers Demo
        </TopBar>
        {availableExamples.map((_example, i) => {
          if (_example.isDemo) return <div key={i}></div>
          return (
            <FileItem
              active={example.name === _example.name}
              key={_example.name}
              onClick={() => selectExample(i)}
            >
              <div>
                <NameWithLogo name={_example.name} icon={_example.icon} />
              </div>
            </FileItem>
          )
        })}
      </Files>
    </SidebarSection>
  );
};

export default Sidebar;
