import { FC, useEffect, useMemo, useState } from 'react';
import { useAppContext } from '../../context';
import { Select } from '@chakra-ui/react'

const UserPicker: FC<{}> = () => {
  const { example, selectId, id } = useAppContext();

  const [users, setUsers] = useState(example.users ?? [])
  useEffect(() => {
    setUsers(example.users ?? [])
  }, [example])

  function selectUser (id: string) {
    setUsers(users.map(user => ({ ...user, selected: id === user.id })))
    selectId(id)
  }

  const value = useMemo(() => {
    console.log(id)
    return id ? users.find(user => user.id === id)?.id : users.find(user => user.selected === true)?.id
  }, [id, users])

  return (
    <div style={{ marginBottom: '10px' }}>
      <Select size='md' value={value} width="100%" onChange={event => selectUser(event.target.value)}>
        {users.map(user => {
          return (<option style={{ backgroundColor: 'gray' }} key={user.id} value={user.id}>{user.label}</option>)
        })}
      </Select>
    </div>
    
  )
}

export default UserPicker
