import { FC } from 'react';
import { Context } from './context';
import Main from './components/index';
import { ChakraProvider } from '@chakra-ui/react'

const App: FC = () => {
  return (
    <>
      <ChakraProvider>
        <Context>
          <Main />
        </Context>
      </ChakraProvider>
    </>
  );
};

export default App;
