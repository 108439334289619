import React, { createContext, useContext, useReducer } from 'react';

import reducer from './reducer';
import * as types from './types';
import { examples } from './data'

const initialState: types.IState = {
  availableExamples: examples,
  example: examples[0],
  selectExample: () => {},
  selectId: () => {},
  toggleCodePanel: () => {},
  showCodePanel: false,
  id: examples[0].users.find(user => user.selected === true)!.id
}

const AppContext = createContext<typeof initialState>(initialState);

const Context = ({ children }: { children?: React.ReactNode }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const selectExample = (index: number) => {
    dispatch({
      type: types.SELECT_EXAMPLE,
      payload: { index },
    })
  }

  const selectId = (id: string) => {
    dispatch({
      type: types.SELECT_ID,
      payload: { id },
    });
  }

  const toggleCodePanel = () => dispatch({ type: types.TOGGLE_CODE_PANEL, payload: {} })

  return (
    <AppContext.Provider
      value={{
        availableExamples: state.availableExamples,
        example: state.example,
        selectExample,
        toggleCodePanel,
        selectId,
        id: state.id,
        showCodePanel: state.showCodePanel
      }}>
      {children}
    </AppContext.Provider>
  );
};

const useAppContext = () => {
  const context = useContext(AppContext);

  if (context === undefined)
    throw new Error('useContext must be used within a AppContext Provider.');

  return context;
};

export { Context, useAppContext };
