import { FC, useEffect, useRef, useState } from 'react';
import { useAppContext } from '../../context';
import { Nav, NavItem, EditorContainer } from './styles';
import NameWithLogo from '../../utils/NameWithLogo';
import { Iframe } from '../../styles/Iframe'

interface Props {
  id: string;
}

const Index: FC<Props> = ({ id }) => {
  const { example } = useAppContext();
  const iFrameRef = useRef<HTMLIFrameElement>(null);
  const links = example.links

  const [currentLink, setCurrentLink] = useState(links[0])

  useEffect(() => {
    setCurrentLink(example.links[0])
  }, [example])

  return (
    <>
      <Nav>
        {links.map((link) => (
          <NavItem
            key={link.name}
            active={link.name === currentLink?.name}
            disabled={link.name === currentLink?.name}
            onClick={() => setCurrentLink(link)}>
            <NameWithLogo name={link.name} icon={link.icon} />
          </NavItem>
        ))}
      </Nav>
      <EditorContainer id={id}>
        {currentLink !== undefined ? <Iframe name="link-iframe" src={currentLink.link} ref={iFrameRef} title="code output" /> : null}
      </EditorContainer>
    </>
  );
};

export default Index;
