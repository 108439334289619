import { StrictMode } from 'react'
import ReactDOM from 'react-dom'

import App from './App'

import 'reseter.css';
import 'react-toastify/dist/ReactToastify.css'


ReactDOM.render(
  <StrictMode>
    <App />
  </StrictMode>,
  document.getElementById('root')
)
