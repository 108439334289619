import * as types from './types';

const reducer = (state: types.IState, action: types.Action): types.IState => {
  switch (action.type) {
    case types.SELECT_EXAMPLE:
      return {
        ...state,
        example: state.availableExamples[action.payload.index]
      };
    case types.SELECT_ID:
      return {
        ...state,
        id: action.payload.id
      };
    case types.TOGGLE_CODE_PANEL:
      return {
        ...state,
        showCodePanel: !state.showCodePanel
      };
    default:
      return state;
  }
};

export default reducer;
