import { Example } from './types'


const generateUniqueID = (length: number) => new Array(length).fill(0).map((elem)=>Math.random().toString(36).substr(2, 1)).join("")
const randomId = generateUniqueID(10)

export const examples: Example[] = [
  {
    name: 'Email',
    category: ['Products'],
    links: [
      {
        name: 'Workflows',
        link: 'https://workflows.reelevant.com/workflows?id=63bae6fcc8634e00072f1733&scope=60d2dcae87a5ca0006335a82'
      },
      {
        name: 'Contents',
        link: 'https://contents.reelevant.com/contents?id=6404cc34d0f4900007d66ce5&scope=60d2dcae87a5ca0006335a82'
      }
    ],
    callback: {
      title: 'Go to website',
      link: ({ id }) => (`https://shop.reelevant.com/collections/all?rlvt-u=${id}`)
    },
    isDemo: true,
    output: () => `https://reelevant-tech.github.io/demo-platform/products.html`,
    users: [{ id: randomId, label: 'Prospect', selected: true }, { id: 'mike@reelevant.com', label: 'Client', selected: false }, { id: 'mike%2Bties@reelevant.com', label: 'Recent buyer - ties', selected: false }, { id: 'mike%2Bheel@reelevant.com', label: 'Recent buyer - heels', selected: false }]
  },
  // {
  //   name: 'Website',
  //   category: ['Products'],
  //   links: [
  //     {
  //       name: 'Workflows - Banner',
  //       link: 'https://workflows.reelevant.com/workflows?id=63bad4934934af0007dc3808&scope=60d2dcae87a5ca0006335a82'
  //     },
  //     {
  //       name: 'Workflows - Popin',
  //       link: 'https://workflows.reelevant.com/workflows?id=63badc3dc8634e00072f1731&scope=60d2dcae87a5ca0006335a82'
  //     }
  //   ],
  //   isDemo: true,
  //   callback: {
  //     title: 'Go to website',
  //     link: ({ id }) => (`https://shop.reelevant.com/collections/all?rlvt-u=${id}`)
  //   },
  //   output: ({ id }) => `https://shop.reelevant.com?rlvt-u=${id}`,
  //   users: [{ id: randomId, label: 'Random user', selected: true }, { id: 'mike%2Bties@reelevant.com', label: 'Buyer - ties', selected: false }, { id: 'mike%2Bheel@reelevant.com', label: 'Buyer - heels', selected: false }]
  // },
  {
    name: 'Mobile',
    category: ['Products'],
    links: [
      {
        name: 'Workflows',
        link: 'https://workflows.reelevant.com/workflows?id=63bb00c64934af0007dc380d&scope=60d2dcae87a5ca0006335a82'
      },
      {
        name: 'Contents - Banner random',
        link: 'https://contents.reelevant.com/contents?id=63bb00e69c9b1200071d29cf&scope=60d2dcae87a5ca0006335a82'
      },
      {
        name: 'Contents - Banner buyer',
        link: 'https://contents.reelevant.com/contents?id=63bc2c5e83e6ce000765b1ef&scope=60d2dcae87a5ca0006335a82'
      }
    ],
    isDemo: true,
    output: () => `https://reelevant-tech.github.io/demo-platform/mobile.html`,
    users: [{ id: randomId, label: 'Prospect', selected: true }, { id: 'mike%2Bties@reelevant.com', label: 'Recent buyer - ties', selected: false }, { id: 'mike%2Bheel@reelevant.com', label: 'Recent buyer - heels', selected: false }]
  },
  {
    name: 'Clarins',
    category: ['Products'],
    links: [
    ],
    isDemo: false,
    output: () => `https://reelevant-tech.github.io/demo-platform/customers/clarins.html`,
    users: []
  },
  {
    name: 'Etam',
    category: ['Products'],
    links: [
    ],
    isDemo: false,
    output: () => `https://reelevant-tech.github.io/demo-platform/customers/etam.html`,
    users: []
  },
  {
    name: 'MadeInDesign',
    category: ['Products'],
    links: [
    ],
    isDemo: false,
    output: () => `https://reelevant-tech.github.io/demo-platform/customers/mid.html`,
    users: []
  },
  {
    name: 'Picard',
    category: ['Products'],
    links: [
    ],
    isDemo: false,
    output: () => `https://reelevant-tech.github.io/demo-platform/customers/picard.html`,
    users: []
  },
  {
    name: 'Parions Sport',
    category: ['Products'],
    links: [
    ],
    isDemo: false,
    output: () => `https://reelevant-tech.github.io/demo-platform/customers/parionsport.html`,
    users: []
  },
  {
    name: 'Zadig',
    category: ['Products'],
    links: [
    ],
    isDemo: false,
    output: () => `https://reelevant-tech.github.io/demo-platform/customers/zadig.html`,
    users: []
  },
  {
    name: 'Ohmycream',
    category: ['Products'],
    links: [
    ],
    isDemo: false,
    output: () => `https://reelevant-tech.github.io/demo-platform/customers/ohmycream.html`,
    users: []
  },
  {
    name: 'Decathlon',
    category: ['Products'],
    links: [
    ],
    isDemo: false,
    output: () => `https://reelevant-tech.github.io/demo-platform/customers/decathlon.html`,
    users: []
  },
  {
    name: 'Place des Tendances',
    category: ['Products'],
    links: [
    ],
    isDemo: false,
    output: () => `https://reelevant-tech.github.io/demo-platform/customers/placedestendances.html`,
    users: []
  },
  {
    name: 'Paris2024',
    category: ['Products'],
    links: [
    ],
    isDemo: false,
    output: () => `https://reelevant-tech.github.io/demo-platform/customers/paris2024.html`,
    users: []
  },
  {
    name: 'FDJ',
    category: ['Products'],
    links: [
    ],
    isDemo: false,
    output: () => `https://reelevant-tech.github.io/demo-platform/customers/fdj.html`,
    users: []
  },
  {
    name: 'Carrefour - Cagnotte',
    category: ['Products'],
    links: [
    ],
    isDemo: false,
    output: () => `https://reelevant-tech.github.io/demo-platform/customers/carrefour_cagnotte.html`,
    users: []
  },
  {
    name: 'Dr Pierre Ricaud',
    category: ['Products'],
    links: [
    ],
    isDemo: false,
    output: () => `https://reelevant-tech.github.io/demo-platform/customers/dpr.html`,
    users: []
  },
  {
    name: 'Best western',
    category: ['Products'],
    links: [
    ],
    isDemo: false,
    output: () => `https://reelevant-tech.github.io/demo-platform/customers/bestwestern.html`,
    users: []
  },
  {
    name: 'Carrefour - Ecatalogue',
    category: ['Products'],
    links: [
    ],
    isDemo: false,
    output: () => `https://reelevant-tech.github.io/demo-platform/customers/carrefour_cata.html`,
    users: []
  },
  {
    name: 'Monoprix - Coupons perso',
    category: ['Products'],
    links: [
    ],
    isDemo: false,
    output: () => `https://reelevant-tech.github.io/demo-platform/customers/monoprix.html`,
    users: []
  }
]