import styled from 'styled-components';

export const OutputContainer = styled.section`
  background-color: #ffffff;

  .floating-callback {
    position: absolute;
    bottom: 1em;
    right: 3em;
    z-index: 2;
  }
`;

export const Nav = styled.nav`
  padding: 8px 6px;
  display: flex;
  background-color: #171717;
  grid-column-gap: 10px;
`;

export const Addressbar = styled.div`
  height: 30px;
  background-color: #f2f2f2;
  flex-grow: 1;
  border-radius: 2px;
  padding-left: 10px;
  font-weight: 400;
  display: flex;
  align-items: center;
`;

export const Address = styled.span`
  padding: 0 10px;
`;

export const FloatingCallback = styled.nav`
  display: flex;
  button {
    background-color: #161616;
    color: #d4d4d4;
    padding: 8px;
    border-radius: 4px;
  }
`;