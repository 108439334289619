import { FC, useRef } from 'react';
import { useAppContext } from '../../context';
import { Nav, Addressbar, Address, OutputContainer, FloatingCallback } from './styles';
import { Iframe } from '../../styles/Iframe'
import refreshSvg from '../../assets/refresh.svg'
import codeSvg from '../../assets/code.svg'
import { useAsyncMemo } from 'use-async-memo'
import axios from 'axios'

interface Props {
  id: string;
}

const Output: FC<Props> = ({ id }) => {
  const context = useAppContext();
  const iFrameRef = useRef<HTMLIFrameElement>(null);
  const containerRef = useRef<HTMLElement>(null)

  const iframe = useAsyncMemo(async () => {
    const url = context.example.output(context)
    // if it isn't a template we can just use the direct url
    if (url.includes('https://reelevant-tech.github.io/demo-platform') === false) {
      return url
    }
    try {
        const res = await axios.get<string>(context.example.output(context), { validateStatus: () => true })
      if (res.status !== 200) return undefined
      const blob = new Blob([res.data.replaceAll('%rlvt-u%', context.id)], { type: 'text/html'})
      return URL.createObjectURL(blob)
    } catch (err) {
      return undefined
    }
  }, [context])

  return (
    <OutputContainer id={id} ref={containerRef}>
      <Nav>
        {context.example.links.length > 0 ? <img onClick={() => context.toggleCodePanel()} height={24} width={24} src={codeSvg} alt="refresh" style={{ cursor: 'pointer' }} /> : null}
        <Addressbar>
          <svg width="16" height="16" viewBox="0 0 24 24" fill="#54BB52">
            <path d="M18 10v-4c0-3.313-2.687-6-6-6s-6 2.687-6 6v4h-3v14h18v-14h-3zm-5 7.723v2.277h-2v-2.277c-.595-.347-1-.984-1-1.723 0-1.104.896-2 2-2s2 .896 2 2c0 .738-.404 1.376-1 1.723zm-5-7.723v-4c0-2.206 1.794-4 4-4 2.205 0 4 1.794 4 4v4h-8z" />
          </svg>
          <Address>{context.example.name}</Address>
        </Addressbar>
        <img onClick={() => (iFrameRef.current!.src += '')} height={24} width={24} src={refreshSvg} alt="refresh" style={{ cursor: 'pointer' }} />
      </Nav>
      {
        iframe !== undefined && <Iframe name="output-iframe" sandbox={`allow-scripts allow-forms allow-same-origin allow-popups allow-popups-to-escape-sandbox allow-presentation allow-top-navigation`} src={iframe} ref={iFrameRef} title="code output" />
      }
      {context.example.callback
        ?
          <FloatingCallback className='floating-callback'>
            <button onClick={() => window.open(context.example.callback!.link(context), '_blank')}>{context.example.callback.title}</button>
          </FloatingCallback>
        : null}
    </OutputContainer>
  );
};

export default Output;
